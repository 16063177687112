<template>
  <v-row
    no-gutters
    dense
    class="py-1"
    justify="start"
  >
    <v-col cols="5" md="6" align-self="start">
      <router-link :to="{ name:'item.view',params: {id:itemId} }">
        <v-img
          :src="item.image | imageUrl('thumb', false)"
          class="rounded"
          max-width="200"
          max-height="200"
        ></v-img>
      </router-link>
    </v-col>
    <v-col cols="7" md="6" class="pl-1" align-self="start">
      <div>
        {{ code }}<br /><router-link :to="{ name:'item.view',params: {id:itemId} }">{{ name }}</router-link>
      </div>
      <div class="pt-1">
        <v-chip v-for="(tag, i) in tags" :key="i" class="mr-1 mb-1" small>{{ tag.name }}</v-chip>
      </div>
      <div class="text-caption">
        Д: {{ sizeLength }} Ш: {{ sizeWidth }} В: {{ sizeHeight }}
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'ListItemCard',
  props: {
    item: {
      type: [Object],
      default: null
    }
  },
  computed: {
    itemId() {
      return this.item?.id ?? ''
    },
    code() {
      return this.item?.code ?? ''
    },
    name() {
      return this.item?.name ?? ''
    },
    tags() {
      return this.item?.tags ?? []
    },
    sizeLength() {
      return this.item?.length ?? 0
    },
    sizeWidth() {
      return this.item?.length ?? 0
    },
    sizeHeight() {
      return this.item?.length ?? 0
    }
  }
}
</script>
